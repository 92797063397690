import type { Language, Region } from '../types/internationalization';
import type { CountryCode } from '../types/address';
import { getLanguagesForCountry, pickLanguage } from './languageHelpers';
import { getCountriesForRegion } from './addressHelpers';

export const DEFAULT_PATH = 'us/en';

export const getRouteForCountryAndLanguage = ({
  country,
  language,
  path = '',
}: {
  country: CountryCode;
  language: Language;
  path?: string;
}): string => {
  return `/${country.toLowerCase()}/${language}/${path}`;
};

export const getRouteForNewCountry = ({
  country,
  acceptLanguageHeader,
  path,
}: {
  country: CountryCode;
  acceptLanguageHeader: string;
  path?: string;
}): string => {
  const availableLanguages = getLanguagesForCountry(country);
  const language = pickLanguage(availableLanguages, acceptLanguageHeader);
  return getRouteForCountryAndLanguage({ country, language, path });
};

export const convertLegacyRoute = ({
  language,
  region,
  country,
  slug,
}: {
  language: Language;
  region: Region;
  country: CountryCode;
  slug: string;
}): string => {
  const availableCountries = getCountriesForRegion(region);
  const availableCountryCodes = availableCountries.map(
    (country) => country.alpha2,
  );
  if (availableCountries.length === 1) {
    return getRouteForCountryAndLanguage({
      country: availableCountryCodes[0],
      language,
      path: slug,
    });
  } else if (availableCountryCodes.includes(country)) {
    return getRouteForCountryAndLanguage({ country, language, path: slug });
  }
  return '/';
};

export const countryAndLanguageRegex = /[a-z]{2}\/[a-z]{2}(?=\/|$)/;

export const isValidPath = (path: string): boolean => {
  const [urlCountry, urlLanguage] = path.split('/');
  const language = urlLanguage as Language;
  const country = urlCountry.toUpperCase();
  const supportedLanguages = getLanguagesForCountry(country);
  return supportedLanguages.includes(language);
};

export const getCountryFromValidPath = (validPath: string) =>
  isValidPath(validPath) ? validPath.split('/')[0] : null;

export const JFRP_FF_NZ_AU = 'jfrp-ff-nz-au';
export const JFRP_FF_GCC = 'jfrp-ff-gcc';
export const JFRP_FF_UK_EU = 'jfrp-ff-uk-eu';
export const JFRP_FF_US_CA = 'jfrp-ff-us-ca';
export const JFRP_FF_ROW = 'jfrp-ff-row';

export const isFFOnForCountry = (
  country: CountryCode,
  flags: Record<string, boolean>,
): boolean => {
  const countryToUse = country.toUpperCase();
  switch (countryToUse) {
    case 'NZ':
    case 'AU':
      return flags[JFRP_FF_NZ_AU];
    case 'QA':
    case 'AE':
    case 'SA':
      return flags[JFRP_FF_GCC];
    case 'AX':
    case 'AT':
    case 'BE':
    case 'BG':
    case 'HR':
    case 'CY':
    case 'CZ':
    case 'DK':
    case 'EE':
    case 'FO':
    case 'FI':
    case 'FR':
    case 'DE':
    case 'GR':
    case 'GL':
    case 'HU':
    case 'IS':
    case 'IE':
    case 'IT':
    case 'LV':
    case 'LT':
    case 'LU':
    case 'MT':
    case 'MC':
    case 'NL':
    case 'NO':
    case 'PL':
    case 'PT':
    case 'RO':
    case 'SK':
    case 'SI':
    case 'ES':
    case 'SE':
    case 'CH':
    case 'GB':
      return flags[JFRP_FF_UK_EU];
    case 'US':
    case 'CA':
      return flags[JFRP_FF_US_CA];
    default: // ROW
      return flags[JFRP_FF_ROW];
  }
};

export const JF_REPLATFORM_ROLLOUT_UUID_AU_NZ =
  'jf-replatform-rollout-uuid-au-nz';
export const JF_REPLATFORM_ROLLOUT_UUID_GCC = 'jf-replatform-rollout-uuid-gcc';
export const JF_REPLATFORM_ROLLOUT_UUID_UK_EU =
  'jf-replatform-rollout-uuid-uk-eu';
export const JF_REPLATFORM_ROLLOUT_UUID_US_CA =
  'jf-replatform-rollout-uuid-us-ca';
export const JF_REPLATFORM_ROLLOUT_UUID_ROW = 'jf-replatform-rollout-uuid-row';

export const JF_REPLATFORM_ROLLOUT_AU_NZ = 'jf-replatform-rollout-au-nz';
export const JF_REPLATFORM_ROLLOUT_GCC = 'jf-replatform-rollout-gcc';
export const JF_REPLATFORM_ROLLOUT_UK_EU = 'jf-replatform-rollout-uk-eu';
export const JF_REPLATFORM_ROLLOUT_US_CA = 'jf-replatform-rollout-us-ca';
export const JF_REPLATFORM_ROLLOUT_ROW = 'jf-replatform-rollout-row';

export const JFRP_EX_NZ_AU = 'jfrp-ex-nz-au-v2';
export const JFRP_EX_GCC = 'jfrp-ex-gcc-v1';
export const JFRP_EX_UK_EU = 'jfrp-ex-uk-eu-v1';
export const JFRP_EX_US_CA = 'jfrp-ex-us-ca-v1';
export const JFRP_EX_ROW = 'jfrp-ex-row-v1';

export const getCookieNames = (
  country: CountryCode,
): {
  experimentName: string;
  featureFlagName: string;
  cookieName: string;
  uuidCookieName: string;
} => {
  const countryToUse = country.toUpperCase();
  switch (countryToUse) {
    case 'NZ':
    case 'AU':
      return {
        experimentName: JFRP_EX_NZ_AU,
        featureFlagName: JFRP_FF_NZ_AU,
        cookieName: JF_REPLATFORM_ROLLOUT_AU_NZ,
        uuidCookieName: JF_REPLATFORM_ROLLOUT_UUID_AU_NZ,
      };
    case 'QA':
    case 'AE':
    case 'SA':
      return {
        experimentName: JFRP_EX_GCC,
        featureFlagName: JFRP_FF_GCC,
        cookieName: JF_REPLATFORM_ROLLOUT_GCC,
        uuidCookieName: JF_REPLATFORM_ROLLOUT_UUID_GCC,
      };
    case 'AX':
    case 'AT':
    case 'BE':
    case 'BG':
    case 'HR':
    case 'CY':
    case 'CZ':
    case 'DK':
    case 'EE':
    case 'FO':
    case 'FI':
    case 'FR':
    case 'DE':
    case 'GR':
    case 'GL':
    case 'HU':
    case 'IS':
    case 'IE':
    case 'IT':
    case 'LV':
    case 'LT':
    case 'LU':
    case 'MT':
    case 'MC':
    case 'NL':
    case 'NO':
    case 'PL':
    case 'PT':
    case 'RO':
    case 'SK':
    case 'SI':
    case 'ES':
    case 'SE':
    case 'CH':
    case 'GB':
      return {
        experimentName: JFRP_EX_UK_EU,
        featureFlagName: JFRP_FF_UK_EU,
        cookieName: JF_REPLATFORM_ROLLOUT_UK_EU,
        uuidCookieName: JF_REPLATFORM_ROLLOUT_UUID_UK_EU,
      };
    case 'US':
    case 'CA':
      return {
        experimentName: JFRP_EX_US_CA,
        featureFlagName: JFRP_FF_US_CA,
        cookieName: JF_REPLATFORM_ROLLOUT_US_CA,
        uuidCookieName: JF_REPLATFORM_ROLLOUT_UUID_US_CA,
      };
    default: // ROW
      return {
        experimentName: JFRP_EX_ROW,
        featureFlagName: JFRP_FF_ROW,
        cookieName: JF_REPLATFORM_ROLLOUT_ROW,
        uuidCookieName: JF_REPLATFORM_ROLLOUT_UUID_ROW,
      };
  }
};
