export const enum CheckoutStep {
  CREATE_ACCOUNT = 'create_account',
  GIFT_PURCHASER_EMAIL = 'gift_purchaser_email',
  SHIPPING_DETAILS = 'shipping_details',
  SHIPPING_METHOD = 'shipping_method',
  EXPRESS_CHECKOUT = 'express_checkout',
  PAYMENT = 'payment',
}

export interface CheckoutStepStatus {
  isCompleted: boolean;
  isLoading: boolean;
  isOpen: boolean;
  error?: string | undefined;
}

export interface CheckoutStepStatusV2 {
  isCompleted: boolean;
  isLoading: boolean;
  isOpen: boolean;
}

export type CheckoutSteps = Partial<{
  [C in CheckoutStep]: CheckoutStepStatus;
}>;
