import { type Country, type Province } from '@whoop/i18n';
import type {
  CheckoutStepStatus,
  CheckoutSteps,
  DropdownOption,
} from '../types';
import { CheckoutStep } from '../types';

export const COMPLETED_STEP = {
  error: undefined,
  isCompleted: true,
  isLoading: false,
  isOpen: false,
};

export const INCOMPLETE_STEP = {
  error: undefined,
  isCompleted: false,
  isLoading: false,
  isOpen: false,
};

export const LOADING_STEP = {
  error: undefined,
  isCompleted: false,
  isLoading: true,
  isOpen: true,
};

export const OPEN_STEP = {
  error: undefined,
  isCompleted: false,
  isLoading: false,
  isOpen: true,
};

export const INITIAL_JOIN_STEPS: CheckoutSteps = {
  [CheckoutStep.CREATE_ACCOUNT]: { ...OPEN_STEP },
  [CheckoutStep.SHIPPING_DETAILS]: { ...INCOMPLETE_STEP },
  [CheckoutStep.SHIPPING_METHOD]: { ...INCOMPLETE_STEP },
  [CheckoutStep.PAYMENT]: { ...INCOMPLETE_STEP },
};

export const INITIAL_GIFT_STEPS: CheckoutSteps = {
  [CheckoutStep.GIFT_PURCHASER_EMAIL]: { ...OPEN_STEP },
  [CheckoutStep.SHIPPING_DETAILS]: { ...INCOMPLETE_STEP },
  [CheckoutStep.SHIPPING_METHOD]: { ...INCOMPLETE_STEP },
  [CheckoutStep.PAYMENT]: { ...INCOMPLETE_STEP },
};

export const INITIAL_RAF_GIFT_STEPS: CheckoutSteps = {
  [CheckoutStep.SHIPPING_DETAILS]: { ...OPEN_STEP },
  [CheckoutStep.SHIPPING_METHOD]: { ...INCOMPLETE_STEP },
  [CheckoutStep.PAYMENT]: { ...INCOMPLETE_STEP },
};

export const CART_UNLOCKED_STEPS = [
  CheckoutStep.CREATE_ACCOUNT,
  CheckoutStep.GIFT_PURCHASER_EMAIL,
  CheckoutStep.SHIPPING_DETAILS,
];

const getErrorStep = (error: string): CheckoutStepStatus => {
  return {
    error,
    isCompleted: false,
    isLoading: false,
    isOpen: true,
  };
};

export const isCheckoutComplete = (steps: CheckoutSteps): boolean => {
  return Object.values(steps).every((status) => status.isCompleted);
};

export const isCheckoutLoading = (steps: CheckoutSteps): boolean => {
  return Object.values(steps).some((status) => status.isLoading);
};

const updateAllSteps = (
  steps: CheckoutSteps,
  index: number,
  currentStepStatus: CheckoutStepStatus,
): CheckoutSteps => {
  const updatedSteps = { ...steps };
  const stepOrder = Object.keys(steps);
  stepOrder.forEach((stepName, stepIndex) => {
    if (stepIndex < index) {
      updatedSteps[stepName as CheckoutStep] = { ...COMPLETED_STEP };
    } else if (stepIndex === index) {
      updatedSteps[stepName as CheckoutStep] = { ...currentStepStatus };
    } else if (stepIndex > index) {
      updatedSteps[stepName as CheckoutStep] = { ...INCOMPLETE_STEP };
    }
  });
  return updatedSteps;
};

export const updateStepsOnCompletion = (
  steps: CheckoutSteps,
  completedStep: CheckoutStep,
): CheckoutSteps => {
  const stepOrder = Object.keys(steps);
  const stepIndex = stepOrder.indexOf(completedStep);
  return updateAllSteps(steps, stepIndex + 1, OPEN_STEP);
};

export const updateStepsOnEdit = (
  steps: CheckoutSteps,
  editedStep: CheckoutStep,
): CheckoutSteps => {
  const stepOrder = Object.keys(steps);
  const stepIndex = stepOrder.indexOf(editedStep);
  return updateAllSteps(steps, stepIndex, OPEN_STEP);
};

export const updateStepsOnError = (
  steps: CheckoutSteps,
  failedStep: CheckoutStep,
  error: string,
): CheckoutSteps => {
  const stepOrder = Object.keys(steps);
  const stepIndex = stepOrder.indexOf(failedStep);
  return updateAllSteps(steps, stepIndex, getErrorStep(error));
};

export const updateStepsOnLoading = (
  steps: CheckoutSteps,
  loadingStep: CheckoutStep,
): CheckoutSteps => {
  const stepOrder = Object.keys(steps);
  const stepIndex = stepOrder.indexOf(loadingStep);
  return updateAllSteps(steps, stepIndex, LOADING_STEP);
};

export const generateIdempotentKey = (): string => {
  return Math.random().toString(36).substring(2, 10);
};

export const getDisplayCountries = (countries: Country[]): DropdownOption[] => {
  return countries.map((country) => {
    return { label: country.name, value: country.alpha2 };
  });
};

export const getDisplayProvinces = (
  provinces: Province[],
): DropdownOption[] => {
  return provinces.map((province) => {
    return { label: province.name, value: province.code };
  });
};
